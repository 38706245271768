import React, { useState, useEffect } from 'react';
import './App.css';
import HomePage from './HomePage';
import UserTimelinePage from './UserTimelinePage';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';



function App() {
  return (
    <div className="App">
      <header className="App-header">
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/timeline/:uid" element={<UserTimelinePage />} />
        </Routes>
      </header>
    </div>
  );
}

export default App;
