
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Skeleton, CircularProgress, Box, Button, Fab, TextField, Typography, useTheme, useMediaQuery, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';
import { Add as AddIcon, Save as SaveIcon } from '@mui/icons-material';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { format, compareAsc } from 'date-fns';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';




const TimelineCreatePage = ({ existingData }) => {
  const [items, setItems] = useState(existingData || []);
  const [showForm, setShowForm] = useState(false);
  const [formState, setFormState] = useState({ title: '', description: '', time: null });
  const navigate = useNavigate();

  const [openDialog, setOpenDialog] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(null);

  const [originalItems, setOriginalItems] = useState([]);
  const [isEditMode, setIsEditMode] = useState(false);

  const [showSaveAlert, setShowSaveAlert] = useState(false); // 저장 완료 알림을 위한 상태 변수

  const [isLoading, setIsLoading] = useState(false);
  const [isItemLoading, setItemIsLoading] = useState(false);



  const skeletonLoader = (
    <Box >
      {Array.from(new Array(3)).map((_, index) => (
        <Box key={index} sx={{ marginBottom: 2 }}>
          <Skeleton variant="text" width="80%" height={30} />
          <Skeleton variant="text" width="60%" />
        </Box>
      ))}
    </Box>
  );


  const handleItemClick = (item, index) => {
    setSelectedItem(item);
    setSelectedIndex(index); // 선택된 항목의 인덱스도 저장
    setOpenDialog(true);
  };



  const handleAddClick = () => {
    setShowForm(true); // 이전에는 이 부분이 팝업을 제어했습니다.
    // 새로운 Dialog를 열기 위해 setOpenDialog를 true로 설정합니다.
    setOpenDialog(true);
    // 새 항목 추가를 위해 selectedItem과 selectedIndex를 초기화합니다.
    setSelectedItem({ title: '', description: '', time: new Date() });
    setSelectedIndex(null); // 새 항목 추가이므로 인덱스는 null입니다.
  };

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setFormState(prev => ({ ...prev, [name]: value }));
  };

  const handleDateChange = (newValue) => {
    setFormState(prev => ({ ...prev, time: newValue }));
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();

    // DatePicker에서 선택된 시간을 ISO 8601 형식의 문자열로 변환
    const formattedTime = formState.time ? formState.time.toISOString() : new Date().toISOString();

    // 새로운 아이템 객체 생성
    const newItem = { ...formState, time: formattedTime, uuid: uuidv4() };

    setItems(prevItems => {
      const newItems = [...prevItems, newItem];
      // 정렬 시, 변환된 시간 문자열을 사용
      newItems.sort((a, b) => compareAsc(new Date(a.time), new Date(b.time)));
      return newItems;
    });

    // 폼 상태 초기화 및 폼 숨김
    setFormState({ title: '', description: '', time: null });

    // 팝업 닫기
    setOpenDialog(false);

    // 선택된 항목과 인덱스 초기화 (필요한 경우)
    setSelectedItem(null);
    setSelectedIndex(null);
  };


  const handleUpdateItems = async () => {
    const changes = {
      create: [],
      update: [],
      delete: []
    };
    console.log("items : ", items);
    console.log("originalItems : ", originalItems);

    // 추가된 항목 식별: 'id' 대신 'uuid' 사용
    const addedItems = items.filter(item => !item.uuid || !originalItems.some(origItem => origItem.uuid === item.uuid));
    changes.create.push(...addedItems);

    // 삭제된 항목 식별: 'id' 대신 'uuid' 사용하여 삭제할 항목의 uuid 목록 생성
    const deletedUuids = originalItems.filter(origItem => !items.some(item => item.uuid === origItem.uuid)).map(item => item.uuid);
    changes.delete.push(...deletedUuids);

    // 수정된 항목 식별: 'id' 대신 'uuid' 사용하여 수정된 항목 식별
    const updatedItems = items.filter(item => {
      const originalItem = originalItems.find(origItem => origItem.uuid === item.uuid);
      return originalItem && (item.title !== originalItem.title || item.description !== originalItem.description || item.time !== originalItem.time);
    });
    changes.update.push(...updatedItems);

    console.log("changes : ", changes);
    if (changes.create.length > 0 || changes.update.length > 0 || changes.delete.length > 0) {
      try {
        const authToken = localStorage.getItem('access_token');
        const updateUrl = 'https://f69ixhxinf.execute-api.ap-southeast-1.amazonaws.com/hestory/timelines/update'; // 서버 엔드포인트 URL

        const response = await axios.post(updateUrl, changes, {
          headers: { 'Authorization': `Bearer ${authToken}` }
        });

        console.log('Update successful:', response.data);
        setOriginalItems([...items]);
        setShowSaveAlert(true);
      } catch (error) {
        console.error('Error updating timeline items:', error);
        // 에러 핸들링 로직 추가
      }
    } else {
      console.log('No change');
    }

  };


  const handleDetailChange = (field, value) => {
    console.log("field : ", field);
    console.log("value : ", value);
    setSelectedItem({ ...selectedItem, [field]: value });
  };

  const handleSaveDetails = () => {
    // 선택된 항목을 업데이트합니다. 선택된 인덱스와 선택된 항목의 정보를 사용하여 업데이트합니다.
    const updatedItems = items.map((item, index) => {
      if (index === selectedIndex) {
        // 날짜 정보를 ISO 문자열로 변환합니다. 이렇게 하면 정렬 시 일관된 형식을 사용할 수 있습니다.
        console.log(selectedItem);
        const isDateObject = !isNaN(Date.parse(selectedItem.time));

        const updatedTime = isDateObject ? new Date(selectedItem.time).toISOString() : selectedItem.time;
        // 선택된 항목에 대한 새로운 객체를 생성하여 업데이트합니다.
        return { ...selectedItem, time: updatedTime };
      }
      return item;
    });

    // 전체 항목 목록을 날짜에 따라 정렬합니다.
    updatedItems.sort((a, b) => compareAsc(new Date(a.time), new Date(b.time)));

    // 업데이트 및 정렬된 항목 목록으로 상태를 업데이트합니다.
    setItems(updatedItems);

    // Dialog를 닫고, 선택된 항목과 인덱스를 초기화합니다.
    setOpenDialog(false);
    setSelectedItem(null);
    setSelectedIndex(null);
  };




  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedItem(null); // Dialog 닫을 때 선택된 아이템 초기화
  };




  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleSave = async () => {
    setIsLoading(true);
    if (isEditMode) {
      await handleUpdateItems(); // 수정 모드일 경우 수정 관련 함수 호출
    } else {
      await handleSaveItems(); // 수정 모드가 아닐 경우 기존 저장 로직 호출
    }
    setIsLoading(false);
  };

  const handleSaveItems = async () => {
    const authToken = localStorage.getItem('access_token');
    const apiUrl = 'https://f69ixhxinf.execute-api.ap-southeast-1.amazonaws.com/hestory/timelines/create';

    console.log(items);
    console.log(authToken);

    try {
      const response = await axios.post(apiUrl, items, {
        headers: {
          'Authorization': `Bearer ${authToken}` // 여기서 yourAuthToken은 실제 토큰으로 대체해야 합니다.
        }
      });
      console.log(response.data); // 성공 메시지 로그

      setShowSaveAlert(true);
    } catch (error) {
      console.error(error); // 에러 처리
    }
  };

  const handleDeleteItem = () => {
    if (selectedIndex !== null) {
      // 선택된 아이템 인덱스를 사용하여 아이템 삭제
      setItems(prevItems => prevItems.filter((_, index) => index !== selectedIndex));
      handleCloseDialog(); // Dialog 닫기 및 초기화
    }
  };

  useEffect(() => {
    const fetchTimelineData = async () => {
      setItemIsLoading(true);
      const authToken = localStorage.getItem('access_token');
      if (!authToken) return; // 로그인하지 않은 상태면 데이터를 불러오지 않음

      const apiUrl = 'https://f69ixhxinf.execute-api.ap-southeast-1.amazonaws.com/hestory/timelines';
      try {
        const response = await axios.get(apiUrl, {
          headers: {
            'Authorization': `Bearer ${authToken}`
          }
        });
        if (response.data.data && response.data.data.length > 0) {
          console.log("values : ", response.data.data);
          const sortedData = response.data.data.sort((a, b) => compareAsc(new Date(a.time), new Date(b.time)));

          setItems(sortedData);
          setOriginalItems([...sortedData]);
          setIsEditMode(true); // 데이터가 있을 경우 수정 모드 활성화
        }
      } catch (error) {
        console.error("Failed to fetch timeline data:", error);
      }
      setItemIsLoading(false);
    };

    fetchTimelineData();
  }, []);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      {isLoading && (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          position="fixed" // 오버레이로 고정
          top={0}
          left={0}
          width="100vw" // 화면 전체 너비
          height="100vh" // 화면 전체 높이
          zIndex={9999} // 다른 컨텐츠 위에 오도록 높은 z-index 값 설정
        >
          <CircularProgress />
        </Box>
      )}

      <Box sx={{ position: 'relative', p: 3, minWidth: '500px' }}>
        {isItemLoading ? (
          skeletonLoader // 데이터 로딩 중 스켈레톤 UI 표시
        ) : (
            <Timeline position="alternate">
              {items.length === 0 && (
                // 초기 세로줄을 표시하는 TimelineItem
                <TimelineItem>
                  <TimelineSeparator>
                    <TimelineDot color="primary" variant="outlined" />
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent />
                </TimelineItem>
              )}
              {items.map((item, index) => (
                <TimelineItem key={index} onClick={() => handleItemClick(item, index)}>
                  <TimelineSeparator>
                    <TimelineDot color="primary" />
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent sx={{
                    py: '12px', px: 2, display: 'flex', flexDirection: 'column', alignItems: 'center', '&:hover': {
                      cursor: 'pointer',
                      backgroundColor: '#2e333d',
                    },
                  }}>
                    <Typography variant="h6" component="span" sx={{ wordBreak: 'break-word' }} >
                      {item.title}
                    </Typography>
                    {item.time && (
                      <Typography component="span" sx={{ opacity: 0.7, color: 'gray', fontSize: '0.8rem', textAlign: 'center', marginTop: '8px' }}>
                        {format(new Date(item.time), 'yyyy.MM.dd')}
                      </Typography>
                    )}
                  </TimelineContent>
                </TimelineItem>
              ))}
              {/* '플러스' 버튼을 위한 TimelineItem 추가 */}
              <TimelineItem>
                <TimelineSeparator>
                  <TimelineDot color="primary" variant="outlined" />
                  {/* 마지막 아이템에는 세로줄을 끝내지 않음 */}
                </TimelineSeparator>
                <TimelineContent>
                  {/* 플러스 버튼 위치 조정 */}
                  <Fab color="primary" aria-label="add" onClick={handleAddClick} sx={{ marginRight: 2 }}>
                    <AddIcon />
                  </Fab>
                  <Fab color="primary" onClick={handleSave} sx={{ boxShadow: 'none' }}>
                    <SaveIcon />
                  </Fab>

                </TimelineContent>
              </TimelineItem>
            </Timeline>
          )}


        <Dialog
          open={openDialog}
          onClose={() => handleCloseDialog()}
          maxWidth="sm"
          fullWidth
          sx={{
            '& .MuiDialog-paper': {
              borderRadius: '10px',
              padding: '20px',
              backgroundColor: '#323842', // 팝업 배경색 조정
            }
          }}
        >
          <DialogTitle sx={{
            textAlign: 'center',
            fontWeight: 'bold',
            color: '#E0E0E0', // 텍스트 색상 조정
          }}>
            타임라인
  </DialogTitle>

          <DialogContent>
            <TextField
              margin="dense"
              label="Title"
              fullWidth
              variant="filled"
              sx={{
                marginBottom: '10px',
                backgroundColor: '#39424e', // 입력 필드 배경색 조정
                '& .MuiFilledInput-input': {
                  color: '#E0E0E0', // 입력 필드 텍스트 색상 조정
                },
                '& label.Mui-focused': {
                  color: '#61dafb', // 포커스 시 라벨 색상 조정
                },
                '& .MuiFilledInput-underline:after': {
                  borderBottomColor: '#61dafb', // 포커스 시 밑줄 색상 조정
                },
              }}
              InputLabelProps={{
                style: { color: '#adb5bd' }, // 라벨 색상 조정
              }}
              value={selectedItem ?.title || ''}
              onChange={(e) => handleDetailChange('title', e.target.value)}
            />
            <TextField
              margin="dense"
              label="Description"
              fullWidth
              variant="filled"
              multiline
              rows={4}
              sx={{
                marginBottom: '10px',
                backgroundColor: '#39424e', // 입력 필드 배경색 조정
                '& .MuiFilledInput-input': {
                  color: '#E0E0E0', // 입력 필드 텍스트 색상 조정
                },
                '& label.Mui-focused': {
                  color: '#61dafb', // 포커스 시 라벨 색상 조정
                },
              }}
              InputLabelProps={{
                style: { color: '#adb5bd' }, // 라벨 색상 조정
              }}
              value={selectedItem ?.description || ''}
              onChange={(e) => handleDetailChange('description', e.target.value)}
            />
            <DatePicker
              label="Time"
              value={selectedItem ?.time ? new Date(selectedItem.time) : null}
              onChange={(newValue) => handleDetailChange('time', newValue)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="filled"
                  fullWidth
                  sx={{
                    backgroundColor: '#39424e', // 입력 필드 배경색 조정
                    '& .MuiFilledInput-input': {
                      color: '#E0E0E0', // 입력 필드 텍스트 색상 조정
                    },
                    '& label.Mui-focused': {
                      color: '#61dafb', // 포커스 시 라벨 색상 조정
                    },
                  }}
                  InputLabelProps={{
                    style: { color: '#adb5bd' }, // 라벨 색상 조정
                  }}
                />
              )}
            />
          </DialogContent>

          <DialogActions sx={{ justifyContent: 'space-between', padding: '20px' }}>
            {/* 왼쪽에 삭제 버튼 배치 */}
            {selectedIndex !== null && ( // 선택된 아이템이 있을 때만 삭제 버튼을 표시
              <Button onClick={handleDeleteItem} sx={{
                backgroundColor: '#f44336', // 삭제 버튼의 배경색을 빨간색 계열로 설정
                color: '#FFFFFF',
                '&:hover': {
                  backgroundColor: '#d32f2f', // 호버 시 배경색 변경
                },
              }}>
                삭제하기
    </Button>
            )}

            {/* 오른쪽에 취소 및 저장 버튼 배치 */}
            <Box>
              <Button onClick={handleCloseDialog} variant="outlined" sx={{
                color: '#E0E0E0',
                borderColor: '#E0E0E0',
                '&:hover': {
                  backgroundColor: 'rgba(224, 224, 224, 0.08)',
                },
                marginRight: '8px', // 버튼 사이의 간격 조정
              }}>
                취소
    </Button>
              <Button onClick={handleSaveDetails} sx={{
                backgroundColor: '#1876d1',
                color: '#FFFFFF',
                '&:hover': {
                  backgroundColor: '#4a9bb8',
                },
              }}>
                저장
    </Button>
            </Box>
          </DialogActions>

        </Dialog>

        <Dialog
          open={openDialog && selectedIndex === null} // selectedIndex가 null일 때만 새 항목 추가 팝업을 표시합니다.
          onClose={handleCloseDialog}
          maxWidth="sm"
          fullWidth
          sx={{
            '& .MuiDialog-paper': {
              borderRadius: '10px',
              padding: '20px',
              backgroundColor: '#323842', // 상세보기 팝업과 유사한 배경색
            }
          }}
        >
          <DialogTitle sx={{
            textAlign: 'center',
            fontWeight: 'bold',
            color: '#E0E0E0', // 상세보기 팝업과 유사한 텍스트 색상
          }}>
            항목 추가
  </DialogTitle>

          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              name="title"
              label="Title"
              type="text"
              fullWidth
              variant="filled"
              value={formState.title}
              onChange={handleFormChange}
              sx={{
                backgroundColor: '#39424e',
                '& .MuiFilledInput-input': {
                  color: '#E0E0E0',
                },
                '& .MuiInputLabel-root': {
                  color: '#adb5bd',
                },
                '& .MuiInputLabel-root.Mui-focused': {
                  color: '#61dafb',
                },
              }}
            />

            <TextField
              margin="dense"
              name="description"
              label="Description"
              type="text"
              fullWidth
              multiline
              rows={4}
              variant="filled"
              value={formState.description}
              onChange={handleFormChange}
              sx={{
                backgroundColor: '#39424e',
                '& .MuiFilledInput-input': {
                  color: '#E0E0E0',
                },
                '& .MuiInputLabel-root': {
                  color: '#adb5bd',
                },
                '& .MuiInputLabel-root.Mui-focused': {
                  color: '#61dafb',
                },
              }}
            />

            <DatePicker
              label="Time"
              value={formState.time}
              onChange={handleDateChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="filled"
                  fullWidth
                  sx={{
                    backgroundColor: '#39424e',
                    '& .MuiFilledInput-input': {
                      color: '#E0E0E0',
                    },
                    '& .MuiInputLabel-root': {
                      color: '#adb5bd',
                    },
                    '& .MuiInputLabel-root.Mui-focused': {
                      color: '#61dafb',
                    },
                  }}
                />
              )}
            />
          </DialogContent>

          <DialogActions sx={{ justifyContent: 'center', padding: '20px' }}>
            <Button onClick={handleCloseDialog} variant="outlined" sx={{
              color: '#E0E0E0',
              borderColor: '#E0E0E0',
              '&:hover': {
                backgroundColor: 'rgba(224, 224, 224, 0.08)',
              },
              margin: '0 10px'
            }}>
              취소
    </Button>
            <Button onClick={handleFormSubmit} sx={{
              backgroundColor: '#1876d1',
              color: '#FFFFFF',
              '&:hover': {
                backgroundColor: '#4a9bb8',
              },
              margin: '0 10px'
            }}>
              추가
    </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={showSaveAlert}
          onClose={() => setShowSaveAlert(false)}
          sx={{
            '& .MuiDialog-paper': {
              backgroundColor: '#323842', // 기존 디자인의 배경색으로 설정
            }
          }}
        >
          <DialogTitle sx={{ color: '#E0E0E0', textAlign: 'center' }}>{"저장 완료 🎉"}</DialogTitle>
          <DialogContent>
            <DialogContentText sx={{ color: '#adb5bd' }}>
              타임라인이 성공적으로 저장되었습니다!
    </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setShowSaveAlert(false)} sx={{
              backgroundColor: '#1876d1', // 버튼 배경색
              color: '#FFFFFF', // 버튼 텍스트 색상
              '&:hover': {
                backgroundColor: '#1876d1', // 호버 시 배경색
              },
            }}>
              확인
    </Button>
          </DialogActions>
        </Dialog>





      </Box>
    </LocalizationProvider>
  );
};

export default TimelineCreatePage;


