// HomePage.js

import React, { useState, useEffect } from 'react';
import GoogleLoginButton from './GoogleLoginButton';
import Button from '@mui/material/Button';
import TimelineCreatePage from './TimelineCreatePage';
import axios from 'axios';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

function HomePage() {
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    const [openDialog, setOpenDialog] = useState(false);
    const [shareUrl, setShareUrl] = useState('');

    useEffect(() => {
        const isLoggedIn = localStorage.getItem('isLoggedIn') === 'true';
        setIsLoggedIn(isLoggedIn);

        if (isLoggedIn) {
            fetchUserInfo(); // 로그인 상태가 유지되고 있을 경우, 사용자 정보를 즉시 가져옵니다.
        }
    }, []);

    const fetchUserInfo = async () => {
        try {
            const authToken = localStorage.getItem('access_token');
            if (!authToken) return; // 로그인하지 않은 상태면 데이터를 불러오지 않음

            console.log("authToken : ", authToken);
            const response = await axios.post('https://f69ixhxinf.execute-api.ap-southeast-1.amazonaws.com/hestory/get_user_info', {}, {
                headers: {
                    'Authorization': `Bearer ${authToken}`
                }
            });


            console.log("response : ", response);
            const userInfo = response.data;

            // 사용자 정보를 로컬 스토리지에 저장합니다.
            localStorage.setItem('userInfo', JSON.stringify(userInfo));
        } catch (error) {
            console.error('Failed to fetch user info:', error);
        }
    };

    const handleLoginSuccess = () => {
        setIsLoggedIn(true);
        localStorage.setItem('isLoggedIn', 'true');
    };

    const handleLogout = () => {
        setIsLoggedIn(false);
        localStorage.clear();
    };

    const handleShareClick = () => {
        const userInfo = JSON.parse(localStorage.getItem('userInfo'));
        console.log(userInfo);
        if (userInfo && userInfo.uuid) {
            const url = `https://hestory.world/timeline/${userInfo.uuid}`;
            setShareUrl(url);
            navigator.clipboard.writeText(url).then(() => {
                setOpenDialog(true);
            });
        } else {
            alert('공유할 정보가 없습니다.');
        }
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    return (
        <div>
            <h1>He story</h1>
            {!isLoggedIn ? (
                <GoogleLoginButton onLoginSuccess={handleLoginSuccess} />
            ) : (
                    <div>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleLogout}
                            style={{ marginBottom: '20px' }}
                        >
                            로그아웃
          </Button>
                        {' '}
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={handleShareClick}
                            style={{ marginBottom: '20px' }}
                        >
                            공유하기
                    </Button>
                        <TimelineCreatePage />
                    </div>
                )}



            <Dialog
                open={openDialog}
                onClose={handleCloseDialog}
                sx={{
                    '& .MuiDialog-paper': {
                        backgroundColor: '#323842', // 기존 디자인의 배경색으로 설정
                    },
                }}
            >
                <DialogTitle sx={{ color: '#E0E0E0', textAlign: 'center' }}>{"링크 복사 🔗"}</DialogTitle>
                <DialogContent>
                    <DialogContentText sx={{ color: '#adb5bd' }}>
                        클립보드에 링크가 복사되었습니다.
    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => window.open(shareUrl, '_blank')} sx={{
                        backgroundColor: '#1876d1', // 버튼 배경색
                        color: '#FFFFFF', // 버튼 텍스트 색상
                        '&:hover': {
                            backgroundColor: '#1876d1', // 호버 시 배경색
                        },
                    }}>
                        이동하기
    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default HomePage;
