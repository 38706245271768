import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom'; // BrowserRouter 임포트

// Firebase 설정
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

// Firebase 구성 객체
const firebaseConfig = {
  apiKey: "AIzaSyB_KJJjrEabXgT1HVtr28Hcd02hp0puLSY",
  authDomain: "hestory-39aae.firebaseapp.com",
  projectId: "hestory-39aae",
  storageBucket: "hestory-39aae.appspot.com",
  messagingSenderId: "629293754045",
  appId: "1:629293754045:web:94045aa841d5ec384239d4",
  measurementId: "G-TDWN91J8J5"
};

// Firebase 초기화
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter> {/* App 컴포넌트를 BrowserRouter로 감싸주기 */}
      <App />
    </BrowserRouter>
  </React.StrictMode>
);

// 성능 측정을 위한 함수 (선택사항)
reportWebVitals();
