// UserTimelinePage.js

import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import UserTimeline from './UserTimeline';
import Button from '@mui/material/Button';

function UserTimelinePage() {
  const { uid } = useParams();

  const navigate = useNavigate(); // useHistory 훅을 사용하여 history 객체에 접근

  // '내 타임라인 만들기' 버튼 클릭 핸들러
  const handleCreateMyTimeline = () => {
    navigate('/'); // 루트 URL로 리다이렉트
  };

  return (
    <div>
      <h1>타임라인</h1>
      <Button
                            variant="contained"
                            color="primary"
                            onClick={handleCreateMyTimeline}
                            style={{ marginBottom: '20px' }}
                        >
                            내 타임라인 만들기
          </Button>
      <UserTimeline uid={uid} />
    </div>
  );
}

export default UserTimelinePage;
