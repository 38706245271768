import React from 'react';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import Button from '@mui/material/Button';
import GoogleIcon from '@mui/icons-material/Google';

import { jwtDecode } from "jwt-decode";

const clientId = "629293754045-trl8emh47b04k8rp27dkosrtmjisop7l.apps.googleusercontent.com";
const serverUrl = 'https://f69ixhxinf.execute-api.ap-southeast-1.amazonaws.com/hestory';

const GoogleLoginButton = ({ onLoginSuccess }) => {
  const handleSuccess = async (response) => {
    console.log('z로그인 성공:', response);
    const token_decoded = jwtDecode(response.credential);

    // 서버로 사용자 정보 전송
    const userInfo = {
      social_id: token_decoded.sub, // Google 고유 사용자 ID
      username: token_decoded.name,
      email: token_decoded.email,
      social_type: 'google',
    };
    console.log(userInfo);
    fetch(`${serverUrl}/login/social`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(userInfo),
    })
    .then(response => response.json())
    .then(data => {
      console.log('백엔드로부터 받은 토큰:', data.access_token);

      localStorage.setItem('isLoggedIn', 'true');
      localStorage.setItem('access_token', data.access_token); // 토큰 저장
      onLoginSuccess();
    })
    .catch(error => {
      console.error('로그인 처리 중 에러 발생:', error);
    });
  };

  const handleFailure = (error) => {
    console.error('로그인 실패:', error);
  };

  return (
    <GoogleOAuthProvider clientId={clientId}>
      <GoogleLogin
        scope="openid profile email"
        onSuccess={handleSuccess}
        onError={handleFailure}
        render={(renderProps) => (
          <Button
            startIcon={<GoogleIcon />}
            variant="contained"
            color="primary"
            onClick={renderProps.onClick}
            disabled={renderProps.disabled}
          >
            Login with Google
          </Button>
        )}
      />
    </GoogleOAuthProvider>
  );
};

export default GoogleLoginButton;
