
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Skeleton, CircularProgress, Box, Button, Fab, TextField, Typography, useTheme, useMediaQuery, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';
import { Add as AddIcon, Save as SaveIcon } from '@mui/icons-material';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { format, compareAsc } from 'date-fns';
import axios from 'axios';




const UserTimeline = ({ uid }) => {
    const [items, setItems] = useState([]);
    const [showForm, setShowForm] = useState(false);
    const [formState, setFormState] = useState({ title: '', description: '', time: null });
    const navigate = useNavigate();

    const [openDialog, setOpenDialog] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const [selectedIndex, setSelectedIndex] = useState(null);

    const [isItemLoading, setItemIsLoading] = useState(false);





    const skeletonLoader = (
        <Box >
            {Array.from(new Array(3)).map((_, index) => (
                <Box key={index} sx={{ marginBottom: 2 }}>
                    <Skeleton variant="text" width="80%" height={30} />
                    <Skeleton variant="text" width="60%" />
                </Box>
            ))}
        </Box>
    );


    const handleItemClick = (item, index) => {
        setSelectedItem(item);
        setSelectedIndex(index); // 선택된 항목의 인덱스도 저장
        setOpenDialog(true);
    };





    const handleCloseDialog = () => {
        setOpenDialog(false);
        setSelectedItem(null); // Dialog 닫을 때 선택된 아이템 초기화
    };




    useEffect(() => {
        const fetchTimelineData = async () => {
            console.log(uid);
            setItemIsLoading(true);

            const apiUrl = `https://f69ixhxinf.execute-api.ap-southeast-1.amazonaws.com/hestory/open_timelines?uuid=${uid}`;
            console.log(apiUrl);
            try {
                const response = await axios.get(apiUrl);
                if (response.data.data && response.data.data.length > 0) {
                    console.log("values : ", response.data.data);
                    const sortedData = response.data.data.sort((a, b) => compareAsc(new Date(a.time), new Date(b.time)));

                    setItems(sortedData);
                }
            } catch (error) {
                console.error("Failed to fetch timeline data:", error);
            }
            setItemIsLoading(false);
        };

        fetchTimelineData();
    }, []);

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Box sx={{ position: 'relative', p: 3, minWidth: '500px' }}>
                {isItemLoading ? (
                    skeletonLoader // 데이터 로딩 중 스켈레톤 UI 표시
                ) : (
                        <Timeline position="alternate">
                            {items.length === 0 && (
                                // 초기 세로줄을 표시하는 TimelineItem
                                <TimelineItem>
                                    <TimelineSeparator>
                                        <TimelineDot color="primary" variant="outlined" />
                                        <TimelineConnector />
                                    </TimelineSeparator>
                                    <TimelineContent />
                                </TimelineItem>
                            )}
                            {items.map((item, index) => (
                                <TimelineItem key={index} onClick={() => handleItemClick(item, index)}>
                                    <TimelineSeparator>
                                        <TimelineDot color="primary" />
                                        <TimelineConnector />
                                    </TimelineSeparator>
                                    <TimelineContent sx={{
                                        py: '12px', px: 2, display: 'flex', flexDirection: 'column', alignItems: 'center', '&:hover': {
                                            cursor: 'pointer',
                                            backgroundColor: '#2e333d',
                                        },
                                    }}>
                                        <Typography variant="h6" component="span" sx={{ wordBreak: 'break-word' }} >
                                            {item.title}
                                        </Typography>
                                        {item.time && (
                                            <Typography component="span" sx={{ opacity: 0.7, color: 'gray', fontSize: '0.8rem', textAlign: 'center', marginTop: '8px' }}>
                                                {format(new Date(item.time), 'yyyy.MM.dd')}
                                            </Typography>
                                        )}
                                    </TimelineContent>
                                </TimelineItem>
                            ))}
                            {/* '플러스' 버튼을 위한 TimelineItem 추가 */}
                            <TimelineItem>
                                <TimelineSeparator>
                                    <TimelineDot color="primary" variant="outlined" />
                                    {/* 마지막 아이템에는 세로줄을 끝내지 않음 */}
                                </TimelineSeparator>
                                <TimelineContent>

                                </TimelineContent>
                            </TimelineItem>
                        </Timeline>
                    )}


                <Dialog
                    open={openDialog}
                    onClose={handleCloseDialog}
                    maxWidth="sm"
                    fullWidth
                    sx={{
                        '& .MuiDialog-paper': {
                            borderRadius: '10px',
                            padding: '20px',
                            backgroundColor: '#323842', // 팝업 배경색 조정
                        },
                    }}
                >
                    <DialogTitle sx={{ textAlign: 'center', fontWeight: 'bold', color: '#E0E0E0' }}>
                        타임라인
  </DialogTitle>

                    <DialogContent>
                        <TextField
                            margin="dense"
                            label="Title"
                            fullWidth
                            variant="filled"
                            InputProps={{
                                readOnly: true,
                                disableUnderline: true,
                                style: { color: '#FFFFFF' } // 흰색 텍스트
                            }}
                            sx={{
                                '& .MuiFilledInput-root': {
                                    backgroundColor: '#39424e', // 입력 필드 배경색 조정
                                    '&.Mui-disabled': {
                                        '-webkit-text-fill-color': '#FFFFFF', // 크롬/사파리에서 비활성화된 텍스트 색상 유지
                                        color: '#FFFFFF', // 기타 브라우저에서 비활성화된 텍스트 색상 유지
                                    }
                                }
                            }}
                            value={selectedItem ?.title || ''}
                        />

                        <TextField
                            margin="dense"
                            label="Description"
                            fullWidth
                            variant="filled"
                            multiline
                            rows={4}
                            InputProps={{
                                readOnly: true,
                                disableUnderline: true,
                                style: { color: '#FFFFFF' } // 흰색 텍스트
                            }}
                            sx={{
                                '& .MuiFilledInput-root': {
                                    backgroundColor: '#39424e', // 입력 필드 배경색 조정
                                    '&.Mui-disabled': {
                                        '-webkit-text-fill-color': '#FFFFFF', // 크롬/사파리에서 비활성화된 텍스트 색상 유지
                                        color: '#FFFFFF', // 기타 브라우저에서 비활성화된 텍스트 색상 유지
                                    }
                                }
                            }}
                            value={selectedItem ?.description || ''}
                        />

                        <TextField
                            margin="dense"
                            label="Date"
                            fullWidth
                            variant="filled"
                            InputProps={{
                                readOnly: true, // 입력 필드를 읽기 전용으로 설정
                                disableUnderline: true,
                                style: { color: '#FFFFFF' } // 흰색 텍스트 적용
                            }}
                            sx={{
                                '& .MuiFilledInput-root': {
                                    backgroundColor: '#39424e', // 입력 필드 배경색 조정
                                    color: '#FFFFFF', // 텍스트 색상 직접 적용
                                }
                            }}
                            value={selectedItem ?.time ? new Date(selectedItem.time).toLocaleDateString() : ''}
                        />


                    </DialogContent>
                </Dialog>

            </Box>
        </LocalizationProvider>
    );
};

export default UserTimeline;


